import { CartLine } from "@msdyn365-commerce/retail-proxy";

/**
 * Merge duplicate cartlines for same product. Sums up quantity and prices to its own cartline.
 */
export function _mergeDuplicateCartlines(cartlines: CartLine[]): CartLine[] {
    return cartlines;

    // @ts-expect-error
    const mergedCartlines: CartLine[] = [];

    cartlines.forEach(cartline => {
        const existingCartline = mergedCartlines.find(cl => cl.ProductId === cartline.ProductId);

        if (existingCartline) {
            const quantity = (existingCartline.Quantity || 0) + (cartline.Quantity || 0);
            const netAmountWithoutTax = cartline.NetAmountWithoutTax
                ? (existingCartline.NetAmountWithoutTax || 0) + cartline.NetAmountWithoutTax
                : (existingCartline.NetAmountWithoutTax || 0) + (cartline.Price || 0) * (cartline.Quantity || 0);

            mergedCartlines[mergedCartlines.indexOf(existingCartline)] = {
                ...existingCartline,
                Quantity: quantity,
                NetAmountWithoutTax: netAmountWithoutTax
            };
        } else {
            mergedCartlines.push({ ...cartline });
        }
    });

    return mergedCartlines;
}
